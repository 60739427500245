import PropTypes from "prop-types";
import React, { useState } from "react";
import { fetchSetNameCollection } from "../../utils/apis";
import { Button, Title, Modal, TextInput, Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { sha3_256_hash_string } from "../../utils/cryptoUtils";

const SetNameCollectionWindow = ({
  onUpdateStampQuota,
  collectionHash,
  openedModalSetName,
  onOpenedModalSetName,
  setNameCollectionIsPinned,
  theme,
}) => {
  const [blackButtonIsHovered, setBlackButtonIsHovered] = useState(false);

  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  //Successfully added name
  const [nameCollectionSuccess, setNameCollectionSuccess] = useState("");

  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: "uncontrolled",
    collectionName: "",

    // functions will be used to validate values at corresponding key
    validate: {
      collectionName: (value) => {
        const hashCollectionSha3 = sha3_256_hash_string(value);

        let foundUnsafeChars;
        if (value) {
          const unsafeCharacters = /[<>#%{}|\\^~[\]`]/g;
          foundUnsafeChars = value.match(unsafeCharacters);
        }
        if ((value && (value.length < 3 || value.length > 250)) || !value) {
          return "Value error: Name must be between 3 and 250 characters long.";
        } else if (foundUnsafeChars) {
          return `Invalid name. Character not allowed: ${[
            ...new Set(foundUnsafeChars),
          ].join(", ")}`;
        }

        if (collectionHash !== hashCollectionSha3) {
          return "Value error: Collection Name not found.";
        }

        return null;
      },
    },
  });

  return (
    <Modal
      opened={openedModalSetName}
      onClose={() => onOpenedModalSetName(false)}
      centered
      size="30rem"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      <Title order={2} align="center" mb={20}>
        Enter the name of the collection and click the &quot;Assign&quot;
        button.
      </Title>

      <form
        onSubmit={form.onSubmit(async (values) => {
          const handleCreateCollection = async () => {
            const requestData = {
              // Name of Collection
              name: values.collectionName,
              cid: collectionHash,
            };

            await fetchSetNameCollection(
              requestData,
              setNameCollectionSuccess,
              onUpdateStampQuota,
              onOpenedModalSetName,
              setNameCollectionIsPinned,
              form,
              setLoading,
            );
          };

          await handleCreateCollection();
        })}
      >
        <Box>
          <TextInput
            classNames={{
              input: "collection-names-input",
            }}
            styles={() => ({
              root: {
                display: "block",
                maxWidth: "100%",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                textAlign: "left",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="Assign collection name:"
            inputWrapperOrder={["label", "input"]}
            inputContainer={(children) => (
              <Box>
                {children}
                <Box
                  className="collection-error-text"
                  style={(theme) => ({
                    fontSize: "0.93em",
                    lineHeight: "0.93em",

                    color: theme.customColors.red,
                    fontFamily: theme.headings.fontFamily.light,
                    width: tabletMediaQuery ? "16rem" : "23rem",
                  })}
                >
                  {form.errors.collectionName}
                </Box>
                <Box
                  className="collection-created-text"
                  style={(theme) => ({
                    fontSize: "0.93em",
                    lineHeight: "0.93em",

                    color: theme.customColors.green,
                    fontFamily: theme.headings.fontFamily.light,
                    width: tabletMediaQuery ? "16rem" : "23rem",
                  })}
                >
                  {nameCollectionSuccess}
                </Box>
              </Box>
            )}
            placeholder="Enter new collection name"
            key={form.key("collectionName")}
            {...form.getInputProps("collectionName")}
          />
          <Button
            type="submit"
            loading={loading}
            variant="filled"
            classNames={{
              root: "collection-names-button",
            }}
            styles={() => ({
              root: {
                fontSize: "1.2em",
                border: "1px solid " + theme.customColors.black,
                marginTop: ".8rem",
                color: blackButtonIsHovered
                  ? theme.customColors.black
                  : theme.customColors.white,
                backgroundColor: blackButtonIsHovered
                  ? theme.customColors.white
                  : theme.customColors.black,
              },
            })}
            onMouseEnter={() => setBlackButtonIsHovered(true)}
            onMouseLeave={() => setBlackButtonIsHovered(false)}
          >
            Assign
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

SetNameCollectionWindow.propTypes = {
  collectionHash: PropTypes.string.isRequired,
  openedModalSetName: PropTypes.bool.isRequired,
  onOpenedModalSetName: PropTypes.func.isRequired,
  setNameCollectionIsPinned: PropTypes.func.isRequired,
  onUpdateStampQuota: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    customColors: PropTypes.shape({
      black: PropTypes.string.isRequired,
      white: PropTypes.string.isRequired,
      red: PropTypes.string.isRequired,
      green: PropTypes.string.isRequired,
    }).isRequired,
    headings: PropTypes.shape({
      fontFamily: PropTypes.shape({
        bold: PropTypes.string.isRequired,
        light: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
export default SetNameCollectionWindow;
