import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMemo } from "react";
import { Anchor, useMantineTheme } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Title,
  Text,
  Container,
  Tooltip,
  Button,
  Flex,
  Box,
  rem,
  Image,
  NavLink,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconQuestionMark,
  IconTriangleInvertedFilled,
} from "@tabler/icons-react";
import { formatCollectionUserFoundPDF } from "../utils/formatCollectionUserFound";
import { handleClickToCopy } from "../utils/copyToClipboard";
import {
  useTableIcons,
  downloadJsonToCsvMissing,
} from "../utils/collectionFound/resultScreen";
import {
  useTableData,
  useTableColumns,
  useTableOptions,
  maxTimeStampDiff,
} from "../utils/collectionFound/matchedDataTable";

import {
  useDataToleranceNew,
  useColumnsToleranceNew,
  useTableToleranceNew,
} from "../utils/collectionFound/toleranceDataTableNew";

const CollectionFound = ({
  contentCollectionFound,
  staticBasePath,
  fileName,
  fileType,
}) => {
  // Maximum timestamp deviation from global record
  const resultMaxTimeStampDiff = maxTimeStampDiff(contentCollectionFound);

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  // Media query to detect if the screen width is less than 64em (1024px)
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  // State for toggling the visibility of the main table
  const [openedTable, handlersTable] = useDisclosure(true);
  const toggleHandlerShowHideTable = () => {
    handlersTable.toggle();
  };

  // Icons used within the tables, provided through a custom utility function
  const faIcons = useMemo(() => useTableIcons(), []);

  // Data preparation for the main table using memoized custom utility function
  const data = useMemo(
    () =>
      useTableData(
        contentCollectionFound,
        handleClickToCopy,
        staticBasePath,
        fileType,
      ),
    [
      contentCollectionFound.missing_in_csv,
      handleClickToCopy,
      staticBasePath,
      fileType,
    ],
  );

  const timeZone = contentCollectionFound?.collection_info?.display_timezone;
  // Column definitions for the main table using a utility function
  const columns = useMemo(() => useTableColumns(fileType, timeZone), []);

  // Configuration options for the main table using Mantine's table library
  const table = useMantineReactTable(
    useTableOptions(columns, data, faIcons, theme),
  );

  // Data preparation for the tolerance table using a custom utility function
  const dataToleranceNew = useMemo(
    () =>
      useDataToleranceNew(
        contentCollectionFound.time_buckets,
        contentCollectionFound.matched_records,
      ),
    [
      contentCollectionFound.time_buckets,
      contentCollectionFound.matched_records,
    ],
  );

  // Column definitions for the tolerance table using a utility function
  const columnsToleranceNew = useMemo(() => useColumnsToleranceNew(theme), []);

  // Configuration options for the tolerance table
  const tableToleranceNew = useMantineReactTable(
    useTableToleranceNew(columnsToleranceNew, dataToleranceNew, theme),
  );

  const [openedTooltip, setOpenedTooltip] = useState(false);

  let closeTimeoutTooltip;

  const handleMouseEnterTooltip = () => {
    clearTimeout(closeTimeoutTooltip);
    setOpenedTooltip(true);
  };

  const handleMouseLeaveTooltip = () => {
    closeTimeoutTooltip = setTimeout(() => {
      setOpenedTooltip(false);
    }, 500);
  };

  const [
    openedTooltipTimestampDifferences,
    setOpenedTooltipTimestampDifferences,
  ] = useState(false);

  let closeTimeoutTooltipTimestampDifferences;

  const handleMouseEnterTooltipTimestampDifferences = () => {
    clearTimeout(closeTimeoutTooltipTimestampDifferences);
    setOpenedTooltipTimestampDifferences(true);
  };

  const handleMouseLeaveTooltipTimestampDifferences = () => {
    closeTimeoutTooltipTimestampDifferences = setTimeout(() => {
      setOpenedTooltipTimestampDifferences(false);
    }, 500);
  };

  const [
    openedTooltipBlockchainCollection,
    setOpenedTooltipBlockchainCollection,
  ] = useState(false);

  let closeTimeoutTooltipBlockchainCollection;

  const handleMouseEnterTooltipBlockchainCollection = () => {
    clearTimeout(closeTimeoutTooltipBlockchainCollection);
    setOpenedTooltipBlockchainCollection(true);
  };

  const handleMouseLeaveTooltipBlockchainCollection = () => {
    closeTimeoutTooltipBlockchainCollection = setTimeout(() => {
      setOpenedTooltipBlockchainCollection(false);
    }, 500);
  };

  return (
    <>
      <div className="rb-screen">
        <div className="rb-header-wrap">
          <Container size="sm">
            <Flex justify="center" direction="column" h="16rem">
              {contentCollectionFound.missing_in_blockchain.length === 0 &&
              contentCollectionFound.missing_in_csv.length === 0 ? (
                <>
                  <Image
                    src={`${staticBasePath}public/images/svg/vb-icon-verified.svg`}
                    w={64}
                    alt="Submitted Data Validated!"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <Flex gap="xs" align="center" justify="center">
                    <Title order={1}>Submitted Data Verified!</Title>
                  </Flex>
                  {resultMaxTimeStampDiff && (
                    <>
                      <Flex gap={7.5} align="center" justify="center">
                        <Image
                          w={19}
                          src={`${staticBasePath}public/images/svg/vb-icon-verify.svg`}
                          alt=""
                        />
                        <Text
                          size="xl"
                          styles={(theme) => ({
                            root: {
                              fontFamily: theme.headings.fontFamily.bold,
                            },
                          })}
                        >
                          {" "}
                          100% Match to Blockchain Data
                        </Text>
                      </Flex>

                      {resultMaxTimeStampDiff !== "0 seconds" && (
                        <Flex gap={7.5} align="center" justify="center">
                          {" "}
                          <Image
                            w={19}
                            src={`${staticBasePath}public/images/svg/info_found.svg`}
                            alt=""
                          />{" "}
                          <Text
                            size="xl"
                            styles={(theme) => ({
                              root: {
                                fontFamily: theme.headings.fontFamily.bold,
                              },
                            })}
                          >
                            Timestamps Match Within {resultMaxTimeStampDiff}{" "}
                            <Anchor
                              size="xs"
                              underline="always"
                              c={theme.customColors.blackLight}
                              href="#container-difference"
                            >
                              (Details Below)
                            </Anchor>
                          </Text>
                        </Flex>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Image
                    src={`${staticBasePath}public/images/svg/not_validate.svg`}
                    alt="Archive Cannot be Verified"
                    w={64}
                    className="rb-result-img align-center"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <Flex gap="xs" align="center" justify="center">
                    <Title order={1}>Archive Cannot be Verified</Title>
                    <Tooltip
                      multiline
                      w={220}
                      withArrow
                      transitionProps={{ transition: "fade-up", duration: 200 }}
                      styles={(theme) => ({
                        tooltip: {
                          color: theme.customColors.black,
                          backgroundColor: theme.customBackgrounds.tooltip,
                          pointerEvents: "auto",
                        },
                      })}
                      opened={openedTooltip}
                      label={
                        <Text
                          onMouseEnter={handleMouseEnterTooltip}
                          onMouseLeave={handleMouseLeaveTooltip}
                        >
                          vBase Verify is unable to verify your Submitted Data
                          vs. the vBase Stamps in the specified Blockchain
                          Collection. Use the details below to find which
                          records/stamps are missing. To read more on how
                          collection verification works,{" "}
                          <Anchor
                            underline="always"
                            c={theme.customColors.blackLight}
                            href="https://docs.vbase.com/"
                            target="_blank"
                          >
                            visit our docs
                          </Anchor>
                          .
                        </Text>
                      }
                    >
                      <Button
                        onMouseEnter={handleMouseEnterTooltip}
                        onMouseLeave={handleMouseLeaveTooltip}
                        styles={(theme) => ({
                          root: {
                            border: `1px solid ${theme.customColors.blackLight}`,
                            width: "1.125rem",
                            height: "1.125rem",
                            padding: "0",
                            backgroundColor: theme.customBackgrounds.tooltip,
                          },
                        })}
                        size="compact-xs"
                        radius="50%"
                      >
                        <IconQuestionMark
                          style={{
                            width: rem(12),
                            height: rem(12),
                            stroke: theme.customBackgrounds.black,
                          }}
                        />
                      </Button>
                    </Tooltip>
                  </Flex>
                  {contentCollectionFound?.missing_in_csv.length > 0 && (
                    <Flex gap="xs" pt="xs" align="center" justify="center">
                      <Text size="lg" className="records-not-found-in-archive">
                        <>
                          <Text span c={theme.customColors.red} inherit>
                            !!
                          </Text>{" "}
                          {contentCollectionFound?.missing_in_csv.length}{" "}
                          Record(s) NOT FOUND in Archive
                        </>
                      </Text>
                    </Flex>
                  )}

                  {contentCollectionFound?.missing_in_blockchain.length > 0 && (
                    <Flex gap="xs" align="center" justify="center">
                      <Text size="lg">
                        <>
                          <Text span c={theme.customColors.red} inherit>
                            !!
                          </Text>{" "}
                          {contentCollectionFound?.missing_in_blockchain.length}{" "}
                          Stamp(s) NOT FOUND in Collection
                        </>
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Container>
        </div>

        <Container
          styles={() => ({
            root: {
              width: "100%",
            },
          })}
          size="lg"
          pl={0}
          pr={0}
        >
          <Flex
            justify="center"
            gap={20}
            align="stretch"
            wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          >
            <Box
              pb={10}
              w={tabletMediaQuery ? "100%" : "50%"}
              style={{
                backgroundColor: theme.customBackgrounds.white,
              }}
            >
              <Box
                pt={16}
                pl={18}
                pb={10}
                pr={18}
                mb={20}
                style={{
                  borderBottom: "1px solid var(--customBackgrounds-tooltip)",
                }}
              >
                <Flex gap="xs" align="center">
                  <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                    Submitted Data
                  </Title>
                </Flex>
              </Box>
              <Box>
                {contentCollectionFound?.collection_info?.collection_name && (
                  <>
                    <Flex
                      pt={16}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                    >
                      <Text title={fileName} size="1em">
                        {formatCollectionUserFoundPDF(fileName, 35, 25, 63)}
                      </Text>
                    </Flex>
                  </>
                )}
              </Box>
            </Box>
            <Box
              pt={18}
              pb={18}
              pl={18}
              pr={18}
              ml={tabletMediaQuery ? "auto" : ""}
              mr={tabletMediaQuery ? "auto" : ""}
              style={{
                backgroundColor: theme.customBackgrounds.blackPrimary,
                height: "5.5rem",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Text
                size="1.3em"
                styles={(theme) => ({
                  root: {
                    textAlign: tabletMediaQuery ? "left" : "center",
                    color: theme.customColors.white,
                  },
                })}
              >
                Verified
              </Text>
              <Text
                size="1.3em"
                styles={(theme) => ({
                  root: {
                    textAlign: tabletMediaQuery ? "left" : "center",
                    color: theme.customColors.white,
                  },
                })}
              >
                vs.
              </Text>
            </Box>
            <Box
              w={tabletMediaQuery ? "100%" : "50%"}
              style={{
                backgroundColor: theme.customBackgrounds.white,
              }}
            >
              <Box
                pt={16}
                pl={18}
                pb={10}
                pr={18}
                mb={20}
                style={{
                  borderBottom: "1px solid var(--customBackgrounds-tooltip)",
                }}
              >
                <Flex gap="xs" align="center">
                  <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                    Collection of Blockchain Stamps
                  </Title>
                  <Tooltip
                    multiline
                    w={220}
                    withArrow
                    transitionProps={{ transition: "fade-up", duration: 200 }}
                    styles={(theme) => ({
                      tooltip: {
                        color: theme.customColors.black,
                        backgroundColor: theme.customBackgrounds.tooltip,
                        pointerEvents: "auto",
                      },
                    })}
                    opened={openedTooltipBlockchainCollection}
                    label={
                      <Box
                        onMouseEnter={
                          handleMouseEnterTooltipBlockchainCollection
                        }
                        onMouseLeave={
                          handleMouseLeaveTooltipBlockchainCollection
                        }
                      >
                        <Text>
                          The details below are for the Collection specified in
                          the Submitted Data.
                        </Text>
                        <Text>
                          A Collection is a group of vBase Stamps created by the
                          Collection Owner. One can think of a Collection as a
                          folder or directory that attributes vBase Stamps to
                          specific projects, datasets, strategies, experiments,
                          etc.{" "}
                        </Text>
                        <Text>
                          The Collection Name is specified at creation by the
                          Collection Owner. The Collection ID is a fingerprint
                          of the Collection Name. To read more about
                          Collections,{" "}
                          <Anchor
                            underline="always"
                            c={theme.customColors.blackLight}
                            href="https://docs.vbase.com/"
                            target="_blank"
                          >
                            visit our docs
                          </Anchor>
                          .
                        </Text>
                      </Box>
                    }
                  >
                    <Button
                      onMouseEnter={handleMouseEnterTooltipBlockchainCollection}
                      onMouseLeave={handleMouseLeaveTooltipBlockchainCollection}
                      styles={(theme) => ({
                        root: {
                          border: `1px solid ${theme.customColors.blackLight}`,
                          width: "1.125rem",
                          height: "1.125rem",
                          padding: "0",
                          backgroundColor: theme.customBackgrounds.tooltip,
                        },
                      })}
                      size="compact-xs"
                      radius="50%"
                    >
                      <IconQuestionMark
                        style={{
                          width: rem(12),
                          height: rem(12),
                          stroke: theme.customBackgrounds.black,
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Flex>
              </Box>
              <Box>
                {contentCollectionFound?.collection_info?.collection_name && (
                  <>
                    <Flex
                      pt={16}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                    >
                      <Text size="1em">Collection Name:</Text>
                      <Text size="1em" className="collection-name">
                        {
                          contentCollectionFound?.collection_info
                            ?.collection_name
                        }
                      </Text>
                    </Flex>
                  </>
                )}

                {contentCollectionFound.collection_info?.collection_id && (
                  <>
                    <Flex
                      pt={0}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                      opacity={0.5}
                    >
                      <Text
                        size="0.88em"
                        styles={(theme) => ({
                          root: {
                            fontFamily: theme.headings.fontFamily.medium,
                          },
                        })}
                      >
                        Collection ID:
                      </Text>
                      <Text size="0.88em">
                        {formatCollectionUserFoundPDF(
                          contentCollectionFound.collection_info?.collection_id,
                        )}
                      </Text>
                      <img
                        className="rb-details-table__copy"
                        src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                        alt="copy"
                        onClick={() =>
                          handleClickToCopy(
                            contentCollectionFound.collection_info
                              ?.collection_id,
                          )
                        }
                      />
                    </Flex>
                  </>
                )}
              </Box>
              <Box pb={20}>
                {contentCollectionFound.collection_info?.user_name && (
                  <>
                    <Flex
                      pt={16}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                    >
                      <Text size="1em">Collection Owner Username:</Text>
                      <Anchor
                        c={theme.customColors.black}
                        underline="always"
                        href={`/verify/user-data/?user=${contentCollectionFound.collection_info?.user_name}`}
                        size="1em"
                        className="collection-user"
                      >
                        {contentCollectionFound.collection_info?.user_name}
                      </Anchor>
                    </Flex>
                  </>
                )}
                {contentCollectionFound.collection_info?.user_address && (
                  <>
                    <Flex
                      pt={0}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                      opacity={0.5}
                    >
                      <Text size="0.88em">
                        Collection Owner Blockchain Address:
                      </Text>

                      <Anchor
                        c={theme.customColors.black}
                        underline="always"
                        href={`/verify/user-data/?user=${contentCollectionFound.collection_info?.user_address}`}
                        size="0.88em"
                      >
                        {formatCollectionUserFoundPDF(
                          contentCollectionFound.collection_info?.user_address,
                          16,
                          7,
                          24,
                        )}
                      </Anchor>
                      <img
                        className="rb-details-table__copy"
                        src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                        alt="copy"
                        onClick={() =>
                          handleClickToCopy(
                            contentCollectionFound.collection_info
                              ?.user_address,
                          )
                        }
                      />
                    </Flex>
                  </>
                )}
              </Box>
            </Box>
          </Flex>
        </Container>

        {resultMaxTimeStampDiff !== "0 seconds" &&
          contentCollectionFound.missing_in_blockchain.length === 0 &&
          contentCollectionFound.missing_in_csv.length === 0 && (
            <Container
              id="container-difference"
              styles={(theme) => ({
                root: {
                  backgroundColor: theme.customBackgrounds.white,
                  width: "100%",
                  paddingBottom: "1em",
                },
              })}
              size="lg"
              pl={0}
              pr={0}
              mt="lg"
            >
              <Box pt={16} pl={18} pb={10} pr={18}>
                <Flex gap="xs" align="center">
                  <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                    Timestamp Differences
                  </Title>
                  <Tooltip
                    multiline
                    w={220}
                    withArrow
                    transitionProps={{ transition: "fade-up", duration: 200 }}
                    styles={(theme) => ({
                      tooltip: {
                        color: theme.customColors.black,
                        backgroundColor: theme.customBackgrounds.tooltip,
                        pointerEvents: "auto",
                      },
                    })}
                    opened={openedTooltipTimestampDifferences}
                    label={
                      <Box>
                        <Text
                          onMouseEnter={
                            handleMouseEnterTooltipTimestampDifferences
                          }
                          onMouseLeave={
                            handleMouseLeaveTooltipTimestampDifferences
                          }
                        >
                          <Text pb={10}>
                            The table below shows how many of the Submitted Data
                            records differed from the blockchain-based
                            timestamps and by how much.
                          </Text>
                          <Text>
                            For record-by-record specifics, see the Record
                            Details section below.
                          </Text>
                        </Text>
                      </Box>
                    }
                  >
                    <Button
                      onMouseEnter={handleMouseEnterTooltipTimestampDifferences}
                      onMouseLeave={handleMouseLeaveTooltipTimestampDifferences}
                      styles={(theme) => ({
                        root: {
                          border: `1px solid ${theme.customColors.blackLight}`,
                          width: "1.125rem",
                          height: "1.125rem",
                          padding: "0",
                          backgroundColor: theme.customBackgrounds.tooltip,
                        },
                      })}
                      size="compact-xs"
                      radius="50%"
                    >
                      <IconQuestionMark
                        style={{
                          width: rem(12),
                          height: rem(12),
                          stroke: theme.customBackgrounds.black,
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Flex>
              </Box>
              <Box pl={18} pr={18}>
                <MantineReactTable table={tableToleranceNew} />
              </Box>
              <Text
                pl={18}
                pr={18}
                pt={10}
                pb={10}
                size="1.25rem"
                c={theme.customColors.red}
                styles={(theme) => ({
                  root: {
                    fontFamily: theme.headings.fontFamily.bold,
                  },
                })}
              >
                Maximum Timestamp Deviation: {resultMaxTimeStampDiff}
              </Text>
            </Container>
          )}

        <Container
          styles={(theme) => ({
            root: {
              backgroundColor: theme.customBackgrounds.white,
              width: "100%",
              paddingBottom: "1em",
            },
          })}
          size="lg"
          pl={0}
          pr={0}
          mt="lg"
        >
          <Box pt={16} pl={18} pb={10} pr={18}>
            <Flex gap="xs" align="center" justify="space-between">
              <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                Record Details
                {contentCollectionFound.missing_in_blockchain.length === 0 &&
                  contentCollectionFound.missing_in_csv.length === 0 && (
                    <> ({contentCollectionFound?.matched_records.length})</>
                  )}
              </Title>

              {contentCollectionFound.missing_in_blockchain.length === 0 &&
                contentCollectionFound.missing_in_csv.length === 0 && (
                  <Box>
                    <NavLink
                      w={100}
                      label={openedTable ? "Hide" : "Show"}
                      onClick={toggleHandlerShowHideTable}
                      opened={openedTable}
                      styles={(theme) => ({
                        root: {
                          backgroundColor: "transparent",
                        },
                        label: {
                          textDecoration: "underline",
                          fontFamily: theme.headings.fontFamily.light,
                          fontSize: "0.875em",
                        },
                      })}
                      leftSection={
                        <Box bg={theme.customBackgrounds.table}>
                          <Flex
                            w={23}
                            h={23}
                            justify="center"
                            align="center"
                            direction="column"
                          >
                            <IconTriangleInvertedFilled
                              style={{
                                transition: "transform 0.3s ease",
                                transform: openedTable
                                  ? "rotate(0deg)"
                                  : "rotate(180deg)",
                              }}
                              size="0.7rem"
                              stroke={1.5}
                            />
                          </Flex>
                        </Box>
                      }
                    />
                  </Box>
                )}
            </Flex>
          </Box>
          <Box pl={18} pr={18}>
            {(contentCollectionFound?.missing_in_csv.length > 0 ||
              contentCollectionFound?.missing_in_blockchain.length > 0) && (
              <Box pb={15}>
                <Anchor
                  underline="always"
                  c={theme.customColors.blackLight}
                  className="download-archive-records"
                  styles={{
                    root: {
                      cursor: "pointer",
                      paddingBottom: "1.75em",
                    },
                  }}
                  onClick={() =>
                    downloadJsonToCsvMissing(
                      [
                        ...(contentCollectionFound?.missing_in_blockchain ||
                          []),
                        ...(contentCollectionFound?.matched_records || []),
                      ],
                      "archive_records.csv",
                    )
                  }
                >
                  Download Archive records
                </Anchor>
              </Box>
            )}

            {(contentCollectionFound?.missing_in_csv.length > 0 ||
              contentCollectionFound?.missing_in_blockchain.length > 0) && (
              <Box>
                <Anchor
                  underline="always"
                  c={theme.customColors.blackLight}
                  className="download-collection-records"
                  styles={{
                    root: {
                      cursor: "pointer",
                      paddingBottom: "1.75em",
                    },
                  }}
                  onClick={() =>
                    downloadJsonToCsvMissing(
                      [
                        ...(contentCollectionFound?.missing_in_csv || []),
                        ...(contentCollectionFound?.matched_records || []),
                      ],
                      "collection_records.csv",
                    )
                  }
                >
                  Download Collection records
                </Anchor>
              </Box>
            )}

            {contentCollectionFound?.matched_records.length > 0 &&
              contentCollectionFound?.missing_in_csv.length === 0 &&
              contentCollectionFound?.missing_in_blockchain.length === 0 && (
                <>
                  {openedTable && <MantineReactTable mb="lg" table={table} />}
                </>
              )}
          </Box>
        </Container>
      </div>
    </>
  );
};

CollectionFound.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  contentCollectionFound: PropTypes.object,
  fileType: PropTypes.string.isRequired,
};
export default CollectionFound;
