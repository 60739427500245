import React from "react";
import { ActionIcon, CopyButton, Flex, rem, Tooltip } from "@mantine/core";
import { formatStampDetails } from "../formatStampDetails";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import moment from "moment/moment";

const formattedDateTime = (date) =>
  moment(date, "YYYY-MM-DD hh:mm:ss").format("MM/DD/YY");

// Function for creating data
export const addressHistoryTableData = (contentAddressHistory) => {
  if (!Array.isArray(contentAddressHistory)) {
    return [];
  }

  return contentAddressHistory.map((item) => ({
    email: item.email,

    name: item.name ?? "-",
    address: (
      <Flex align="center" gap={2}>
        {item.address ? formatStampDetails(item.address) : "-"}
        <CopyButton value={item.address} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? "Copied" : "Copy"}
              withArrow
              position="right"
            >
              <ActionIcon
                color={copied ? "teal" : "gray"}
                variant="subtle"
                onClick={copy}
              >
                {copied ? (
                  <IconCheck style={{ width: rem(16) }} />
                ) : (
                  <IconCopy style={{ width: rem(16) }} />
                )}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Flex>
    ),
    is_verified: item.is_verified ? "Yes" : "No",
    date_from: item.date_from,
    date_to: item.date_to ?? "",
  }));
};

// Function for creating columns
export const addressHistoryTableColumns = () => [
  {
    accessorKey: "email",
    header: "Email",
    size: 100,
    sortingFn: (rowA, rowB) => {
      const cidA = String(rowA.original.email || "").toLowerCase();
      const cidB = String(rowB.original.email || "").toLowerCase();

      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "name",
    header: "Name",
    size: 100,
    sortingFn: (rowA, rowB) => {
      const cidA = String(rowA.original.name || "").toLowerCase();
      const cidB = String(rowB.original.name || "").toLowerCase();

      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "address",
    header: "Address",
    size: 100,
    sortingFn: (rowA, rowB) => {
      const cidA = rowA.original.address.props?.children?.[0] || "";
      const cidB = rowB.original.address.props?.children?.[0] || "";
      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "is_verified",
    header: "Is Verified",
    size: 120,
    sortingFn: (rowFirst, rowSecond) => {
      const dataFirst = String(
        rowFirst.original.is_verified || "",
      ).toLowerCase();
      const dataSecond = String(
        rowSecond.original.is_verified || "",
      ).toLowerCase();

      if (dataFirst < dataSecond) return -1;
      if (dataFirst > dataSecond) return 1;
      return 0;
    },
  },
  {
    accessorKey: "date_from",
    header: "Date From",
    size: 180,
    cell: ({ row }) => formattedDateTime(row.original.date_from), // Render formatted date
    sortingFn: (rowA, rowB) => {
      const dateFirst = moment(
        rowA.original.date_from,
        "MMM. DD, YYYY, hh:mm A",
      ).toDate();
      const dateSecond = moment(
        rowB.original.date_from,
        "MMM. DD, YYYY, hh:mm A",
      ).toDate();

      if (dateFirst < dateSecond) return -1;
      if (dateFirst > dateSecond) return 1;
      return 0;
    },
  },
  {
    accessorKey: "date_to",
    header: "Date To",
    size: 180,
    cell: ({ row }) => formattedDateTime(row.original.date_to), // Render formatted date
    sortingFn: (rowFirst, rowSecond) => {
      const dateFirst = moment(
        rowFirst.original.date_to,
        "MMM. DD, YYYY, hh:mm A",
      ).toDate();
      const dateSecond = moment(
        rowSecond.original.date_to,
        "MMM. DD, YYYY, hh:mm A",
      ).toDate();

      if (dateFirst < dateSecond) return -1;
      if (dateFirst > dateSecond) return 1;
      return 0;
    },
  },
];

// Function for configuring the table
export const addressHistoryTableOptions = (columns, data, faIcons, theme) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 12 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
      paddingLeft: "0.5em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table html-table-custom",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
    className: "html-table-custom-wrap",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
      fontSize: "1.142em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
      fontSize: "1.142em",
      fontFamily: theme.headings.fontFamily.bold,
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});
