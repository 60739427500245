import PropTypes from "prop-types";
import React, { useMemo } from "react";
import {
  addressHistoryTableColumns,
  addressHistoryTableData,
  addressHistoryTableOptions,
} from "../../utils/UserProfile/addressHistoryTable";
import { useTableIcons } from "../../utils/collectionFound/resultScreen";
import { Text, Flex, useMantineTheme, Modal, Title } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
const UserAddressHistory = ({
  userAddressHistoryData,
  setUserAddressHistoryData,
}) => {
  const handleClickRemoveUserAddressHistory = () => {
    setUserAddressHistoryData("");
  };

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  // Icons used within the tables, provided through a custom utility function
  const faIcons = useMemo(() => useTableIcons(), []);

  const dataAddressHistory = useMemo(
    () => addressHistoryTableData(userAddressHistoryData.addresses, theme),
    [userAddressHistoryData, theme],
  );

  // Column definitions for the main table using a utility function
  const columnsAddressHistory = addressHistoryTableColumns();

  // Configuration options for the main table using Mantine's table library
  const tableAddressHistory = useMantineReactTable(
    addressHistoryTableOptions(
      columnsAddressHistory,
      dataAddressHistory,
      faIcons,
      theme,
    ),
  );

  return (
    <>
      {userAddressHistoryData && (
        <Modal
          opened={true}
          onClose={handleClickRemoveUserAddressHistory}
          centered
          size="60rem"
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <Flex align="center" justify="center">
            <Title
              order={2}
              styles={() => ({
                root: {
                  fontSize: "1.5em",
                  paddingBottom: "2em",
                },
              })}
            >
              User Address History
            </Title>
          </Flex>
          {userAddressHistoryData.addresses &&
          userAddressHistoryData.addresses.length > 0 ? (
            <MantineReactTable mb="lg" table={tableAddressHistory} />
          ) : (
            <Text
              styles={() => ({
                root: {
                  textAlign: "center",
                },
              })}
            >
              No addresses available
            </Text>
          )}
        </Modal>
      )}
    </>
  );
};

UserAddressHistory.propTypes = {
  userAddressHistoryData: PropTypes.shape({
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        street: PropTypes.string,
      }),
    ),
  }).isRequired,
  setUserAddressHistoryData: PropTypes.func.isRequired,
};

export default UserAddressHistory;
