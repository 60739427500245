/**
 * Extracts the datetime string from the given file name.
 * @param {*} fileName
 * @returns string | null
 */
export const extractDatetime = (fileName) => {
  // List of regex patterns corresponding to the datetime formats
  const patterns = [
    /\d{8}T\d{6}[-+]\d{2}:\d{2}/, // yyyyMMdd'T'HHmmss±HH:MM
    /\d{8}T\d{6}[-+]\d{4}/, // yyyyMMdd'T'HHmmss±HHMM
    /\d{8}_\d{6}[-+]\d{2}:\d{2}/, // yyyyMMdd_HHmmss±HH:MM
    /\d{8}_\d{6}[-+]\d{4}/, // yyyyMMdd_HHmmss±HHMM
    /\d{8}_\d{6}/, // yyyyMMdd_HHmmss (added for underscore format)
    /\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}[-+]\d{2}:\d{2}/, // yyyy-MM-dd_HH-mm-ss±HH:MM
    /\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}[-+]\d{4}/, // yyyy-MM-dd_HH-mm-ss±HHMM
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[-+]\d{2}:\d{2}/, // yyyy-MM-dd'T'HH:mm:ss±HH:MM
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[-+]\d{4}/, // yyyy-MM-dd'T'HH:mm:ss±HHMM
    /\d{14}[-+]\d{2}:\d{2}/, // yyyyMMddHHmmss±HH:MM
    /\d{14}[-+]\d{4}/, // yyyyMMddHHmmss±HHMM
    /\d{8}T\d{6}Z/, // yyyyMMdd'T'HHmmssZ
    /\d{8}_\d{6}Z/, // yyyyMMdd_HHmmssZ
    /\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}Z/, // yyyy-MM-dd_HH-mm-ssZ
    /\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}/, // yyyy-MM-dd_HH-mm-ss
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/, // yyyy-MM-dd'T'HH:mm:ssZ
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/, // yyyy-MM-dd'T'HH:mm:ss
    /\d{8}T\d{6}/, // yyyyMMdd'T'HHmmss
    /\d{14}Z/, // yyyyMMddHHmmssZ
    /\d{14}/, // yyyyMMddHHmmss
    /\d{12}/, // yyyyMMddHHmm
    /\d{10}/, // yyyyMMddHH
    /\d{8}/, // yyyyMMdd
    /\d{4}-\d{2}-\d{2}_\d{6}[-+]\d{2}:\d{2}/, // yyyy-MM-dd_HHmmss±HH:MM
    /\d{4}-\d{2}-\d{2}_\d{6}[-+]\d{4}/, // yyyy-MM-dd_HHmmss±HHMM
    /\d{4}-\d{2}-\d{2}_\d{6}Z/, // yyyy-MM-dd_HHmmssZ
    /\d{4}-\d{2}-\d{2}_\d{6}/, // yyyy-MM-dd_HHmmss
    /\d{4}-\d{2}-\d{2}/, // yyyy-MM-dd
  ];

  for (const pattern of patterns) {
    const match = fileName.match(pattern);
    if (match) {
      let datetime = match[0];

      // Check for and replace the ±HHMM offset with ±HH:MM
      const offsetMatch = datetime.match(/([-+])(\d{2})(\d{2})$/); // Match ±HHMM at the end
      if (offsetMatch) {
        const [, sign, hours, minutes] = offsetMatch; // Extract the sign, hours, and minutes
        datetime = datetime.replace(
          /([-+]\d{4})$/,
          `${sign}${hours}:${minutes}`,
        ); // Replace with ±HH:MM
      }

      return datetime; // Return the matched and modified datetime string
    }
  }

  return null;
};
