import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UserAddressHistory from "../components/user-profile/UserAddressHistory";
import UserDelete from "../components/user-profile/UserDelete";
import UserChangePassword from "../components/user-profile/UserChangePassword";
import { useDisclosure } from "@mantine/hooks";
import CollectionCreatingModal from "../components/CollectionCreatingModal";
import { useForm } from "@mantine/form";
import { Flex, Box, useMantineTheme, Title, Text, Tabs } from "@mantine/core";

import UserInfo from "../components/user-profile/UserInfo";
import Billing from "../components/user-profile/Billing";
import AccountSettings from "../components/user-profile/AccountSettings";
import UsageStatistics from "../components/user-profile/UsageStatistics";
import CollectionNames from "../components/user-profile/CollectionNames";

const UserProfile = ({ staticBasePath, djangoContext }) => {
  const theme = useMantineTheme();

  const [changedUserName, setChangedUserName] = useState(false);

  const [userAddressHistoryData, setUserAddressHistoryData] = useState("");

  const [userDelete, setUserDelete] = useState(false);

  const [changePassword, setChangePassword] = useState(false);

  // Update user Quota after creating Collection
  const [updateStampQuota, setUpdateStampQuota] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleUpdateStampQuota = useCallback((date) => {
    setUpdateStampQuota(date);
  }, []);

  // Show confirmation create Collection
  const [
    showConfirmationCollectionCreate,
    setShowConfirmationCollectionCreate,
  ] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleShowConfirmationCollectionCreate = useCallback((isDisabled) => {
    setShowConfirmationCollectionCreate(isDisabled);
  }, []);

  //State for storing the name
  const [nameCollection, setNameCollection] = useState("");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameCollection = useCallback((collection) => {
    setNameCollection(collection);
  }, []);

  //Error adding name
  const [nameCollectionError, setNameCollectionError] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameCollectionError = useCallback((collectionError) => {
    setNameCollectionError(collectionError);
  }, []);

  //Successfully added name
  const [nameCollectionSuccess, setNameCollectionSuccess] = useState("");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameCollectionSuccess = useCallback((collectionSuccess) => {
    setNameCollectionSuccess(collectionSuccess);
  }, []);

  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    mode: "uncontrolled",
    collectionName: "",
    collectionDescription: "",
    // functions will be used to validate values at corresponding key
    validate: {
      collectionName: (value) => {
        let foundUnsafeChars;
        if (value) {
          const unsafeCharacters = /[<>#%{}|\\^~[\]`]/g;
          foundUnsafeChars = value.match(unsafeCharacters);
        }
        if ((value && (value.length < 3 || value.length > 250)) || !value) {
          return "Value error: Name must be between 3 and 250 characters long.";
        } else if (foundUnsafeChars) {
          return `Invalid name. Character not allowed: ${[
            ...new Set(foundUnsafeChars),
          ].join(", ")}`;
        }

        return null;
      },
      collectionDescription: (value) => {
        let foundUnsafeChars;
        if (value) {
          const unsafeCharacters = /[<>#%{}|\\^~[\]`]/g;
          foundUnsafeChars = value.match(unsafeCharacters);
        }
        if (value && value.length > 2600) {
          return "Value error: Description must contain less than 2600 characters long.";
        } else if (foundUnsafeChars) {
          return `Invalid description. Character not allowed: ${[
            ...new Set(foundUnsafeChars),
          ].join(", ")}`;
        }

        return null;
      },
    },
  });

  // Initialize the activeTab state from the URL hash
  const [activeTab, setActiveTab] = useState(() => {
    // Extract the hash from the URL (without the '#' symbol)
    const hash = window.location.hash.slice(1);
    return hash || "user_info"; // Default to 'Stamp History' tab if no hash is present
  });

  useEffect(() => {
    // Handler for hash change events
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        setActiveTab(hash); // Update the active tab based on the hash
      }
    };

    // Listen for changes to the URL hash
    window.addEventListener("hashchange", handleHashChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // Function to handle tab change
  const handleTabChange = (value) => {
    setActiveTab(value); // Update the active tab
    window.location.hash = value; // Update the URL hash when the tab changes
  };
  return (
    <>
      <CollectionCreatingModal
        titleLoading="One moment! Creating new Collection in progress..."
        textLoading="Can take 20 seconds, but usually faster!"
        titleConfirmation="Confirm New Collection"
        textConfirmation="This Collection will be permanently associated with your Blockchain Address. This operation costs 1 stamp credit."
        nameCollection={nameCollection}
        onNameCollection={handleNameCollection}
        onNameCollectionSuccess={handleNameCollectionSuccess}
        onNameCollectionError={handleNameCollectionError}
        onShowConfirmationCollectionCreate={
          handleShowConfirmationCollectionCreate
        }
        onUpdateStampQuota={handleUpdateStampQuota}
        opened={opened}
        close={close}
        form={form}
      />

      <Header
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
        changedUserName={changedUserName}
        updateStampQuota={updateStampQuota}
      />
      <Flex
        justify="center"
        styles={() => ({
          root: {
            paddingTop: "6em",
            paddingBottom: "2em",
          },
        })}
      >
        <Title size="3em" order={1}>
          User Profile
        </Title>
      </Flex>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        color={theme.customColors.black}
        defaultValue="user_info"
        styles={() => ({
          root: {
            width: "100%",
          },
          tabLabel: {
            fontSize: "1.3em",
          },
        })}
      >
        <Tabs.List justify="center">
          <Tabs.Tab value="user_info">
            <Text
              styles={() => ({
                root: {
                  opacity: activeTab === "user_info" ? "1" : "0.5",
                },
              })}
            >
              User Info
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="billing">
            <Text
              styles={() => ({
                root: {
                  opacity: activeTab === "billing" ? "1" : "0.5",
                },
              })}
            >
              Billing
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="account_settings">
            <Text
              styles={() => ({
                root: {
                  opacity: activeTab === "account_settings" ? "1" : "0.5",
                },
              })}
            >
              Account Settings
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="usage_statistics">
            <Text
              styles={() => ({
                root: {
                  opacity: activeTab === "usage_statistics" ? "1" : "0.5",
                },
              })}
            >
              Usage Statistics
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="collections">
            <Text
              styles={() => ({
                root: {
                  opacity: activeTab === "collections" ? "1" : "0.5",
                },
              })}
            >
              Collections
            </Text>
          </Tabs.Tab>
        </Tabs.List>

        <Box
          style={(theme) => ({
            backgroundColor: theme.customBackgrounds.userProfileTabContent,
          })}
        >
          <Box
            style={() => ({
              maxWidth: "60rem",
              marginLeft: "auto",
              marginRight: "auto",
              width: "calc(100% - 2em)",
            })}
          >
            <Tabs.Panel value="user_info">
              <UserInfo
                setUserAddressHistoryData={setUserAddressHistoryData}
                setUserDelete={setUserDelete}
                setChangePassword={setChangePassword}
                setChangedUserName={setChangedUserName}
                changedUserName={changedUserName}
              />
            </Tabs.Panel>

            <Tabs.Panel value="billing">
              <Billing />
            </Tabs.Panel>

            <Tabs.Panel value="account_settings">
              <AccountSettings />
            </Tabs.Panel>
            <Tabs.Panel value="usage_statistics">
              <UsageStatistics />
            </Tabs.Panel>
            <Tabs.Panel value="collections">
              <CollectionNames
                onUpdateStampQuota={handleUpdateStampQuota}
                onShowConfirmationCollectionCreate={
                  handleShowConfirmationCollectionCreate
                }
                showConfirmationCollectionCreate={
                  showConfirmationCollectionCreate
                }
                onNameCollection={handleNameCollection}
                nameCollection={nameCollection}
                nameCollectionSuccess={nameCollectionSuccess}
                onNameCollectionError={handleNameCollectionError}
                nameCollectionError={nameCollectionError}
                open={open}
                form={form}
              />
            </Tabs.Panel>
          </Box>
        </Box>
      </Tabs>

      <Footer staticBasePath={staticBasePath} />

      {userAddressHistoryData ? (
        <UserAddressHistory
          userAddressHistoryData={userAddressHistoryData}
          setUserAddressHistoryData={setUserAddressHistoryData}
        />
      ) : (
        ""
      )}

      {userDelete ? (
        <UserDelete
          staticBasePath={staticBasePath}
          setUserDelete={setUserDelete}
        />
      ) : (
        false
      )}

      {changePassword ? (
        <UserChangePassword
          staticBasePath={staticBasePath}
          setChangePassword={setChangePassword}
        />
      ) : (
        false
      )}
    </>
  );
};
UserProfile.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
  onUpdateStampQuota: PropTypes.bool,
  showConfirmationCollectionCreate: PropTypes.func,
  handleNameCollection: PropTypes.func,
};

export default UserProfile;
