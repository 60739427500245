import { notifications } from "@mantine/notifications";

export const parseMetadata = (lines, defaultCollectionTimezone) => {
  if (lines.length < 2) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file: file must have at least two lines",
      color: "red",
    });
    return null;
  }

  const headers = lines[0]?.split(",").map((header) => header.trim());
  const data = lines[1]?.split(",").map((value) => value.trim());
  if (!headers || headers.length < 2) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file format: missing or malformed headers",
      color: "red",
    });
    return null;
  }

  if (!data || data.length < 2) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file format: missing or malformed data",
      color: "red",
    });
    return false;
  }

  const headerMap = headers.reduce((map, header, index) => {
    map[header] = index;
    return map;
  }, {});

  const requiredHeaders = ["collection_name", "user_address"];
  for (const header of requiredHeaders) {
    if (!(header in headerMap)) {
      notifications.show({
        title: "Error",
        message: `Missing required header '${header}' in metadata file`,
        color: "red",
      });
      return null;
    }
  }

  const optionalHeaders = ["collection_timezone"];
  const [collectionName, userAddress] = requiredHeaders.map(
    (header) => data[headerMap[header]],
  );

  const collectionTimezone =
    optionalHeaders.includes("collection_timezone") &&
    "collection_timezone" in headerMap
      ? data[headerMap["collection_timezone"]]
      : defaultCollectionTimezone;

  if (!collectionName || !userAddress) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file: missing required data",
      color: "red",
    });
    return null;
  }

  // Validate collectionTimezone if it is present
  if (collectionTimezone) {
    const timezonePattern =
      /^(-12:00|-11:00|-10:00|-09:30|-09:00|-08:00|-07:00|-06:00|-05:00|-04:00|-03:30|-03:00|-02:00|-01:00|\+00:00|\+01:00|\+02:00|\+03:00|\+03:30|\+04:00|\+04:30|\+05:00|\+05:30|\+05:45|\+06:00|\+06:30|\+07:00|\+08:00|\+08:45|\+09:00|\+09:30|\+10:00|\+10:30|\+11:00|\+12:00|\+12:45|\+13:00|\+14:00)$/;
    if (!timezonePattern.test(collectionTimezone)) {
      notifications.show({
        title: "Error",
        message: `Invalid timezone format: '${collectionTimezone}'. Expected format: '+01:00' or '-05:00'`,
        color: "red",
      });
      return null;
    }
  }

  return { collectionName, userAddress, collectionTimezone };
};
