//fetch Form
import { MIME_TYPES } from "@mantine/dropzone";
import { processZip } from "./zipFileTransformer";
import { processCsv } from "./csvFileTransformer";

// Fetch form data and submit the collection file to the server
export const foundCollectionFetch = async (collectionData, csrfToken) => {
  const formData = new FormData();
  formData.append(
    "file",
    new Blob([collectionData], { type: "text/csv" }),
    "file.csv",
  );

  try {
    const response = await fetch("/verify/verify-user-collection/", {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken, // Include the CSRF token
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData.error };
    }
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Error uploading CSV");
  }
};

// Handle dropped files (CSV or ZIP) and process them
export const handleDropProcess = (
  droppedFiles,
  setFiles,
  form,
  setNewData,
  onErrorMessage,
) => {
  setFiles(droppedFiles);

  droppedFiles.forEach((file) => {
    // Read the file as text
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result; // Get the content of the CSV file

      if (file.type === MIME_TYPES.csv) {
        // Process CSV with the hash
        await processCSVWithHash(
          content,
          setFiles,
          form,
          setNewData,
          onErrorMessage,
        );
      } else if (file.type === MIME_TYPES.zip) {
        await processZIPWithHash(content, setFiles, form, setNewData);
      }
    };
    if (file.type === MIME_TYPES.zip) {
      reader.readAsArrayBuffer(file); // Read ZIP as binary
    } else {
      reader.readAsText(file); // Read CSV as text
    }
  });
};

/**
 * Process CSV data with metadata and hashing
 * @param {*} csvData
 * @param {*} setFiles
 * @param {*} form
 * @param {*} setNewData
 * @param {*} onErrorMessage
 * @returns
 */
/* eslint-enable no-unused-vars */
const processCSVWithHash = async (
  csvData,
  setFiles,
  form,
  setNewData,
  onErrorMessage,
) => {
  const finalCsv = await processCsv(csvData, onErrorMessage);
  if (finalCsv) {
    setNewData(finalCsv);
    form.setValues({ new_csv: finalCsv });
  } else {
    setFiles([]);
    form.reset();
  }
  return finalCsv;
};

/**
 * Process ZIP data and generate new CSV with hashing
 * @param {*} zipData
 * @param {*} setFiles
 * @param {*} form
 * @param {*} setNewData
 * @param {*} onErrorMessage
 * @returns
 */
const processZIPWithHash = async (zipData, setFiles, form, setNewData) => {
  const finalCsv = await processZip(zipData);
  if (finalCsv) {
    setNewData(finalCsv);
    form.setValues({ new_csv: finalCsv });
  } else {
    setFiles([]);
    form.reset();
  }
  return finalCsv;
};
