import React, { useState, useEffect } from "react";
import { URLs } from "../../utils/apis";
import { Box, CopyButton, Button, Flex, PasswordInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const AccountSettings = () => {
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    api_key: "",
    private_key: "",
  });

  // Fetch user data from API
  useEffect(() => {
    const fetchQuotasData = async () => {
      try {
        const response = await fetch(URLs.PROFILE_API_KEYS_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setInputValues({
          api_key: data.api_key || "",
          private_key: data.private_key || "",
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchQuotasData();
  }, []); // Empty dependency array means this effect runs once on mount

  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }

  return (
    <Box
      className="billing"
      style={() => ({
        paddingTop: 83,
        paddingBottom: 100,
      })}
    >
      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <PasswordInput
          styles={(theme) => ({
            root: {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: tabletMediaQuery ? "flex-start" : "center",
            },
            label: {
              paddingRight: 20,
              fontFamily: theme.headings.fontFamily.bold,
              fontSize: "1.2em",
              width: 236,
              textAlign: tabletMediaQuery ? "left" : "right",
            },
            input: {
              paddingRight: 20,
              border: "1px solid rgba(112, 112, 112, 0.5019607843)",
              fontSize: "1rem",

              lineHeight: "1.25rem",
              maxWidth: "100%",
              width: tabletMediaQuery ? "100%" : "22rem",
            },
            wrapper: {
              width: tabletMediaQuery ? "100%" : "auto",
            },
          })}
          label="API Key:"
          readOnly
          value={inputValues["api_key"]}
        />
        <CopyButton value={inputValues["api_key"]}>
          {({ copied, copy }) => (
            <Button
              variant="transparent"
              styles={(theme) => ({
                root: {
                  fontSize: "1.2em",
                  textDecoration: "underline",
                  paddingLeft: "0",
                  width: 90,
                  textAlign: "left",
                  color: theme.customColors.blackLight,
                },
              })}
              onClick={copy}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyButton>
      </Flex>
      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <PasswordInput
          styles={(theme) => ({
            root: {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: tabletMediaQuery ? "flex-start" : "center",
            },
            label: {
              paddingRight: 20,
              fontFamily: theme.headings.fontFamily.bold,
              fontSize: "1.2em",
              width: 236,
              textAlign: tabletMediaQuery ? "left" : "right",
            },
            input: {
              paddingRight: 20,
              border: "1px solid rgba(112, 112, 112, 0.5019607843)",
              fontSize: "1rem",
              lineHeight: "1.25rem",
              maxWidth: "100%",
              width: tabletMediaQuery ? "100%" : "22rem",
            },
            wrapper: {
              width: tabletMediaQuery ? "100%" : "auto",
            },
          })}
          label="Private Key:"
          readOnly
          value={inputValues["private_key"]}
        />
        <CopyButton value={inputValues["private_key"]}>
          {({ copied, copy }) => (
            <Button
              variant="transparent"
              styles={(theme) => ({
                root: {
                  fontSize: "1.2em",
                  textDecoration: "underline",
                  paddingLeft: "0",
                  width: 90,
                  textAlign: "left",
                  color: theme.customColors.blackLight,
                },
              })}
              onClick={copy}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyButton>
      </Flex>
    </Box>
  );
};

export default AccountSettings;
