import React from "react";
import { createRoot } from "react-dom/client";
import { MantineProvider, createTheme, rem } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

const theme = createTheme({
  // Font Family
  fontFamily: {
    primary: "Proxima Nova, sans-serif",
    secondary: "Arial, Helvetica, sans-serif",
    light: "Proxima Nova Light, sans-serif",
    lightItalic: "Proxima Nova Light Italic, sans-serif",
    bold: "Proxima Nova Semibold, sans-serif",
    medium: "Proxima Nova Medium, sans-serif",
  },

  // Font Size
  fontSizes: {
    xs: rem(10),
    sm: rem(14),
    md: rem(15),
    lg: rem(16),
    xl: rem(17),
  },

  // Colors
  customColors: {
    black: "#000000",
    blackLight: "#707070",
    white: "#ffffff",
    red: "#FF0000",
    green: "green",
    animationLoader: "#e9e9e9",
    userProfileTabsBorder: "#e0e0e0",
    tableBorder: "1px solid rgba(112,112,112, 0.2)",
  },

  // Backgrounds
  customBackgrounds: {
    blackPrimary: "#1B3028",
    black: "#000000",
    white: "#ffffff",
    tooltip: "#EDF0E5",
    table: "#EDF0E5",
    userProfileTabContent: "#ededed",
    collectionFoundBlock: "#EDF0E5",
    button: "#1B2F28",
    verifyStampZone: "#f8f9fa",
    verifyStampZoneHover: "#f3f4f5",
  },
});

const setCSSVariables = (theme) => {
  const root = document.documentElement;

  Object.entries(theme.fontFamily).forEach(([key, value]) => {
    root.style.setProperty(`--fontFamily-${key}`, value);
  });

  Object.entries(theme.customColors).forEach(([key, value]) => {
    root.style.setProperty(`--customColors-${key}`, value);
  });

  Object.entries(theme.customBackgrounds).forEach(([key, value]) => {
    root.style.setProperty(`--customBackgrounds-${key}`, value);
  });
};

setCSSVariables(theme);

import StampForm from "./pages/StampForm";
import VerifyForm from "./pages/VerifyForm";
import PricingForm from "./pages/PricingForm";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import UserProfile from "./pages/UserProfile";
import SubscriptionNotFoundPage from "./pages/SubscriptionNotFoundPage";
import Reports from "./pages/Reports";
import ReportsProducer from "./pages/ReportsProducer";
import UserFound from "./pages/UserFound";

const props = window.props || {};
const staticBasePath = "/static/";

const stampRootMount = document.getElementById("stamp-root");
const verifyRootMount = document.getElementById("verify-root");
const headerRootMount = document.getElementById("header-root");
const footerRootMount = document.getElementById("footer-root");
const profileRootMount = document.getElementById("profile-root");
const userProfileRootMount = document.getElementById("user-profile-root");
const billingRootMount = document.getElementById("billing-root");
const billingNotFoundRootMount = document.getElementById(
  "billing-not-found-root",
);
const reportsRootMount = document.getElementById("reports-root");
const reportsProducerRootMount = document.getElementById(
  "reports-producer-root",
);
const userFoundRootMount = document.getElementById("user-found-root");

function getValue(key) {
  const element = document.getElementById(key);
  if (element) {
    // Clean up the value by trimming and removing surrounding quotes
    let cleanedText = element.textContent.trim();
    if (cleanedText.startsWith('"') && cleanedText.endsWith('"')) {
      cleanedText = cleanedText.slice(1, -1);
    }
    return cleanedText;
  }
  return "";
}

function getValueAsBoolean(key) {
  const element = document.getElementById(key);
  if (element) {
    const trimmedText = element.textContent.trim().toLowerCase();
    return trimmedText === "true";
  }
  return false;
}

const userIsAuthenticated = getValueAsBoolean("user-is-authenticated") || false;
const userEmail = getValue("user-email");
const userShortName = getValue("user-short-name");
const userNameValue = getValue("user-name");

const djangoContext = {
  userIsAuthenticated,
  userEmail,
  userShortName,
  userNameValue,
};

if (stampRootMount) {
  const root = createRoot(stampRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <StampForm
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}

if (headerRootMount) {
  const root = createRoot(headerRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Header
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}
if (footerRootMount) {
  const root = createRoot(footerRootMount);
  root.render(<Footer {...props} staticBasePath={staticBasePath} />);
}

if (verifyRootMount) {
  const root = createRoot(verifyRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <Notifications />
      <VerifyForm
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}

if (profileRootMount) {
  const root = createRoot(profileRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <PricingForm
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}

if (billingRootMount) {
  const root = createRoot(billingRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <SubscriptionSuccessPage
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}

if (billingNotFoundRootMount) {
  const root = createRoot(billingNotFoundRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <SubscriptionNotFoundPage
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}

if (userProfileRootMount) {
  const root = createRoot(userProfileRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <Notifications />
      <UserProfile
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}

if (reportsRootMount) {
  const root = createRoot(reportsRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Reports
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}

if (reportsProducerRootMount) {
  const root = createRoot(reportsProducerRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <ReportsProducer
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}
if (userFoundRootMount) {
  const root = createRoot(userFoundRootMount);
  root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <UserFound
        {...props}
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
      />
    </MantineProvider>,
  );
}
