import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Group,
  Text,
  rem,
  Button,
  TextInput,
  Container,
  Flex,
  useMantineTheme,
  Anchor,
  Box,
} from "@mantine/core";
import {
  IconXboxX,
  IconFolderOpen,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  foundCollectionFetch,
  handleDropProcess,
} from "../utils/collectionFound/general";
import { useMediaQuery } from "@mantine/hooks";

const VerifyCollection = ({
  props,
  onShowCollectionFound,
  onContentCollectionFound,
  onFileName,
  onErrorMessage,
  onFileType,
  isAuthenticated,
}) => {
  const [files, setFiles] = useState([]);

  const [newData, setNewData] = useState("");
  const [loading, setLoading] = useState(false);

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  const [dropzoneIsHovered, setDropzoneIsHovered] = useState(false);

  const handleDrop = async (droppedFiles) => {
    if (droppedFiles.length > 0) {
      const fileType = droppedFiles[0].type;
      onFileType(fileType); // Pass the file type to the callback
    }

    await handleDropProcess(
      droppedFiles,
      setFiles,
      form,
      setNewData,
      onErrorMessage,
    );
  };

  // Previews (only one file)
  const preview = files.length > 0 ? files[0].name : null;

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      new_csv: "",
    },
  });

  //Submit form
  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);

      const csrfToken = document.querySelector(
        "[name=csrfmiddlewaretoken]",
      ).value;

      try {
        const result = await foundCollectionFetch(values.new_csv, csrfToken);

        if (result.success) {
          onFileName(preview);
          setFiles([]);
          setNewData("");
          form.reset();
          onShowCollectionFound(true);
          onContentCollectionFound(result.data);
        } else {
          console.error(result.error);
          notifications.show({
            title: "Error",
            message: result.error,
            color: "red",
          });
          onErrorMessage(result.error);
        }
      } catch (error) {
        console.error("Error:", error);
        notifications.show({
          title: "Error",
          message: "Error uploading CSV",
          color: "red",
        });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  // Remove file
  const handleClickRemoveFile = () => {
    form.reset();
    setFiles([]);
    setNewData("");
  };
  return (
    <div id="verify-method-csv__screen" className="rb-method-csv__screen">
      <div className="rb-method">
        <Flex
          direction="column"
          gap={20}
          justify="flex-start"
          align="center"
          classNames={{
            root: "csv-screen-container",
          }}
          styles={{
            root: {
              borderRadius: "0.1em",
              backgroundColor: theme.customBackgrounds.verifyStampZone,
              transition: "transform 135ms ease-out",
              margin: "auto",
              border: "1px dashed rgba(112, 112, 112, 0.5)",
              height: "auto",
              width: "calc(100% - 3em)",
              maxWidth: "60rem",
            },
          }}
        >
          {isAuthenticated ? (
            <Dropzone
              w="100%"
              onDrop={handleDrop}
              justify="center"
              styles={{
                root: {
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "0 1.2em 1.2em 1.2em",
                  cursor: "pointer",
                  backgroundColor: dropzoneIsHovered
                    ? theme.customBackgrounds.verifyStampZoneHover
                    : theme.customBackgrounds.verifyStampZone,
                },
              }}
              onMouseEnter={() => setDropzoneIsHovered(true)}
              onMouseLeave={() => setDropzoneIsHovered(false)}
              onReject={(files) => console.log("rejected files", files)}
              accept={[MIME_TYPES.csv, MIME_TYPES.zip]}
              {...props}
            >
              <Box
                style={{
                  paddingTop: "6em",
                  paddingBottom: "4em",
                }}
              >
                <Group
                  styles={{
                    root: { display: "block" },
                  }}
                  justify="center"
                  gap="xs"
                  mih={50}
                  style={{ pointerEvents: "none" }}
                >
                  <div>
                    <Button
                      size="xl"
                      color={theme.customBackgrounds.button}
                      styles={{
                        root: {
                          paddingLeft: tabletMediaQuery ? ".25em" : "1.25em",
                          paddingRight: tabletMediaQuery ? ".25em" : "1.25em",
                        },
                      }}
                    >
                      <Flex align="center" gap={tabletMediaQuery ? "10" : "20"}>
                        <Dropzone.Accept>
                          <IconUpload
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: theme.customColors.white,
                            }}
                            stroke={1}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            size={52}
                            color="var(--mantine-color-red-6)"
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          <IconFolderOpen
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: theme.customColors.white,
                            }}
                            stroke={1}
                          />
                        </Dropzone.Idle>
                        <Text
                          styles={{
                            root: {
                              padding: "0",
                              fontSize: "0.9em",
                              maxWidth: "calc(100% - 4rem)",
                            },
                          }}
                        >
                          Load ZIP or Long CSV archive
                        </Text>
                      </Flex>
                    </Button>
                    <Text
                      c={theme.customColors.blackLight}
                      inline
                      mt={7}
                      styles={{
                        root: {
                          paddingTop: "0.5em",
                          paddingBottom: "0",
                          fontSize: "1em",
                          fontFamily: theme.headings.fontFamily.blackLight,
                        },
                      }}
                    >
                      Or just drag and drop file here
                    </Text>

                    <Text
                      c={theme.customColors.blackLight}
                      inline
                      mt={7}
                      styles={{
                        root: {
                          paddingTop: "0.625em",
                          fontSize: "1em",
                          fontFamily: theme.headings.fontFamily.blackLight,
                        },
                      }}
                    >
                      (You can only load an archive with the{" "}
                      <Anchor
                        c="dimmed"
                        href="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-verify"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                        style={{ pointerEvents: "auto" }}
                      >
                        {" "}
                        appropriate structure
                      </Anchor>
                      )
                    </Text>
                  </div>
                </Group>

                {preview && (
                  <div>
                    File Loaded: <b>{preview}</b>
                    <IconXboxX
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClickRemoveFile();
                      }}
                      style={{
                        width: rem(18),
                        height: rem(18),
                        color: "var(--customColors-primary)",
                        marginLeft: "0.3em",
                        cursor: "pointer",
                        pointerEvents: "auto",
                      }}
                      stroke={1.5}
                    />
                  </div>
                )}

                {newData && (
                  <Container
                    size="26rem"
                    classNames={{
                      root: "rb-text-left w-100",
                    }}
                  >
                    <form onSubmit={form.onSubmit(handleFormSubmit)}>
                      <TextInput
                        pb="md"
                        type="hidden"
                        key={form.key("new_csv")}
                        {...form.getInputProps("new_csv")}
                      />

                      <Button
                        classNames={{
                          root: "collection-validate-submit",
                        }}
                        type="submit"
                        loading={loading}
                        styles={(theme) => ({
                          root: {
                            display: "block",
                            maxWidth: "none",
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor:
                              theme.customBackgrounds.blackPrimary,
                            pointerEvents: "auto",
                          },
                        })}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        Validate
                      </Button>
                    </form>
                  </Container>
                )}
              </Box>
            </Dropzone>
          ) : (
            <Flex
              styles={{
                root: {
                  minHeight: "17.3rem",
                  maxWidth: "21rem",
                  paddingTop: "4.5rem",
                },
              }}
            >
              <Text>
                Please <a href="/accounts/signup/">register</a> for a free
                account and/or <a href="/accounts/login/">log in</a> to verify a
                collection.
              </Text>
            </Flex>
          )}
        </Flex>
      </div>
    </div>
  );
};

VerifyCollection.propTypes = {
  onShowCollectionFound: PropTypes.func.isRequired,
  onFileName: PropTypes.func.isRequired,
  onErrorMessage: PropTypes.func.isRequired,
  props: PropTypes.object,
  onContentCollectionFound: PropTypes.func,
  onFileType: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
};
export default VerifyCollection;
