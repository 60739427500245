import PropTypes from "prop-types";
import React, { useState } from "react";
import { fetchPutDescriptionCollection } from "../../utils/apis";
import { Button, Flex, Modal, Textarea } from "@mantine/core";

const DescriptionCollectionWindow = ({
  collectionID,
  onDescription,
  openedModalDescription,
  onOpenedModalDescription,
  theme,
  form,
}) => {
  const [blackButtonIsHovered, setBlackButtonIsHovered] = useState(false);

  return (
    <Modal
      opened={openedModalDescription}
      onClose={() => onOpenedModalDescription(false)}
      centered
      size="30rem"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          // Description of Collection
          const description = values.collectionDescription;

          await fetchPutDescriptionCollection(
            collectionID,
            description,
            onOpenedModalDescription,
            onDescription,
            form,
          );
        })}
      >
        <Textarea
          styles={() => ({
            input: {
              minHeight: "10em",
            },
          })}
          label="Description (optional)"
          placeholder="Description"
          key={form.key("collectionDescription")}
          {...form.getInputProps("collectionDescription")}
        />

        <Flex justify="center" gap="20" mb={20} mt={20}>
          <Button
            type="submit"
            variant="filled"
            color={theme.customColors.black}
            classNames={{
              root: "modal-button-submit",
            }}
            styles={() => ({
              root: {
                fontSize: "1.2em",
                color: blackButtonIsHovered
                  ? theme.customColors.black
                  : theme.customColors.white,
                backgroundColor: blackButtonIsHovered
                  ? theme.customColors.white
                  : theme.customColors.black,
                border: "1px solid " + theme.customColors.black,
              },
            })}
            onMouseEnter={() => setBlackButtonIsHovered(true)}
            onMouseLeave={() => setBlackButtonIsHovered(false)}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

DescriptionCollectionWindow.propTypes = {
  collectionID: PropTypes.string,
  openedModalDescription: PropTypes.bool.isRequired,
  onDescription: PropTypes.bool.isRequired,
  onOpenedModalDescription: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    customColors: PropTypes.shape({
      black: PropTypes.string.isRequired,
      white: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    key: PropTypes.string.isRequired,
    getInputProps: PropTypes.string.isRequired,
  }).isRequired,
};
export default DescriptionCollectionWindow;
