import JSZip from "jszip";
import { sha3_256_hash_bytes } from "../cryptoUtils";
import { notifications } from "@mantine/notifications";
import { parseMetadata } from "./parseMetadata";
import { stringToTimestamp } from "./stringToTimestamp";
import { extractDatetime } from "./extractDatetime";

/**
 * Read a ZIP file and return its contents
 */
const readZipFile = async (zipFile) => {
  const zip = new JSZip();
  return zip.loadAsync(zipFile);
};

/***
 * Extract and format a timestamp from a CSV file name
 */
const extractAndFormatTimestamp = (fileName, collectionTimezone) => {
  try {
    const extractedDatetime = extractDatetime(fileName);
    const date = stringToTimestamp(extractedDatetime, collectionTimezone);
    return date.toUTC().toFormat("yyyy-MM-dd HH:mm:ss+00:00");
  } catch (error) {
    console.log(error);
    notifications.show({
      title: "Error",
      message: error.message,
      color: "red",
    });
    return null;
  }
};
/**
 * Return a formatted CSV line for a given file
 * @param {string} filePath - Path to the file within the ZIP
 * @param {any} arrayBuffer - ArrayBuffer of the file's content
 * @param {string} collectionTimezone  - Timezone offset for the collection
 * @returns {string} - Formatted CSV line
 */
const getCsvStampSummaryForFile = (
  filePath,
  arrayBuffer,
  collectionTimezone,
) => {
  const fileName = filePath.split("/").pop();
  const timestamp = extractAndFormatTimestamp(fileName, collectionTimezone);
  const byteArray = new Uint8Array(arrayBuffer);
  const hash = sha3_256_hash_bytes(byteArray);
  return `${timestamp},${hash},${fileName}`;
};

// Generate a CSV file from the contents of a ZIP file
const generateCsvFromZip = async (zipContent) => {
  let csvMetadata = ""; // Metadata CSV content
  let csvOutput = "t,c,f\n"; // Data CSV header
  let defaultTimezone = "";

  const ignoreFiles = (file, filePath, fileName) => {
    return (
      file.dir || filePath.includes("__MACOSX") || fileName.startsWith(".")
    );
  };
  // First pass: Filter and process only vbase_metadata.csv
  const tasks = Object.entries(zipContent.files).map(
    async ([filePath, file]) => {
      const fileName = filePath.split("/").pop();
      if (ignoreFiles(file, filePath, fileName)) {
        return;
      }

      if (filePath.endsWith("vbase_metadata.csv")) {
        const fileContent = await file.async("string");
        const lines = fileContent.split("\n");
        const { collectionName, userAddress, collectionTimezone } =
          parseMetadata(lines, defaultTimezone);
        if (collectionName && userAddress) {
          csvMetadata = `collection_name,user_address,collection_timezone\n${collectionName},${userAddress},${collectionTimezone}\n`;
          defaultTimezone = collectionTimezone || defaultTimezone; // Update default timezone
        }
      }
    },
  );

  // Wait for the metadata processing to finish
  await Promise.all(tasks);

  // Second pass: Process all files except vbase_metadata.csv
  await Promise.all(
    Object.entries(zipContent.files).map(async ([filePath, file]) => {
      const fileName = filePath.split("/").pop();
      if (ignoreFiles(file, filePath, fileName)) {
        return;
      }

      if (!filePath.endsWith("vbase_metadata.csv")) {
        const arrayBuffer = await file.async("uint8array");
        const processedLine = getCsvStampSummaryForFile(
          filePath,
          arrayBuffer,
          defaultTimezone,
        );
        if (processedLine) {
          csvOutput += processedLine + "\n";
        }
      }
    }),
  );

  return csvMetadata + csvOutput;
};

// Process a ZIP file and return a formatted CSV string
export const processZip = async (zipFile) => {
  const zipContent = await readZipFile(zipFile);

  return generateCsvFromZip(zipContent);
};
