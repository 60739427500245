import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  handleButtonClickUserAddressHistory,
  submitFormChangeUserName,
} from "../../utils/userProfile";
import { URLs } from "../../utils/apis";

import { useForm } from "@mantine/form";
import {
  TextInput,
  Button,
  Flex,
  Box,
  CopyButton,
  Tooltip,
  ActionIcon,
  rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCheck, IconCopy } from "@tabler/icons-react";

// Media query to detect if the screen width is less than 64em (1024px)

const UserInfo = ({
  setUserAddressHistoryData,
  setUserDelete,
  setChangePassword,
  setChangedUserName,
  changedUserName,
}) => {
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  const mobileMediaQuery = useMediaQuery("(max-width: 31.5em)");

  const [enableName, setEnableName] = useState(false);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      email: "",
      last_address: "",
      date_joined: "",
      last_is_verified: "Unverified",
    },
    enhanceGetInputProps: (payload) => {
      if (enableName && payload.field === "name") {
        return { disabled: false };
      }
      return { disabled: true };
    },
    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) => {
        if (value.length < 3) {
          return "Input must be at least 3 characters";
        }
        if (value.length > 70) {
          return "Input must be less than 70 characters";
        }

        const regex = /^[\w\s!@#$%&*()_\-+=.,'":;?]+$/;
        if (!regex.test(value)) {
          return "Input contains invalid characters. Allowed characters: ! @ # $ % & * ( ) _ - + = . , ' \" : ; ?";
        }
        return null;
      },
    },
  });

  // Temporarily hide items in development
  const [hideInProgress, setHideInProgress] = useState(true); // eslint-disable-line no-unused-vars

  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Fetch user data from API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(URLs.USER_INFO_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const modifiedData = {
          ...data,
          last_is_verified: data.last_is_verified ? "Verified" : "Unverified",
        };
        form.initialize(modifiedData);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [changedUserName]); // Updates after the Username changes

  const handleButtonClickUserDelete = () => {
    setUserDelete(true);
  };

  const handleButtonClickChangePassword = () => {
    setChangePassword(true);
  };
  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }

  return (
    <Box
      className="user-info"
      style={() => ({
        paddingTop: 83,
        paddingBottom: 100,
      })}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            await submitFormChangeUserName(
              values,
              "name",
              form,
              setEnableName,
              setChangedUserName,
            );
          } catch (error) {
            console.error("Submission failed:", error);
          }
        })}
      >
        <Flex
          classNames={{
            root: "user-name-block",
          }}
          gap={20}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
        >
          <TextInput
            styles={(theme) => ({
              root: {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: tabletMediaQuery ? "flex-start" : "center",
                maxWidth: "100%",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                width: 236,
                textAlign: tabletMediaQuery ? "left" : "right",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="Display Name:"
            inputWrapperOrder={["label", "input"]}
            inputContainer={(children) => (
              <Box>
                {children}
                <Box
                  style={(theme) => ({
                    fontSize: "0.93em",
                    lineHeight: "0.93em",
                    position: "absolute",
                    color: theme.customColors.red,
                    fontFamily: theme.headings.fontFamily.light,
                    width: "23rem",
                  })}
                >
                  {form.errors.name}
                </Box>
              </Box>
            )}
            placeholder="Name"
            key={form.key("name")}
            {...form.getInputProps("name")}
          />

          {enableName ? (
            <button
              style={{
                fontSize: "1.2em",
                textDecoration: "underline",
                paddingLeft: "0",
                cursor: "pointer",
                width: 90,
                textAlign: "left",
              }}
            >
              Save
            </button>
          ) : (
            <Button
              type="button"
              variant="transparent"
              color="#000"
              styles={() => ({
                root: {
                  fontSize: "1.2em",
                  textDecoration: "underline",
                  paddingLeft: "0",
                  width: 90,
                  textAlign: "left",
                },
              })}
              onClick={() => setEnableName((prev) => !prev)}
            >
              Change
            </Button>
          )}
        </Flex>

        <Flex
          gap={20}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
        >
          <TextInput
            styles={(theme) => ({
              root: {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: tabletMediaQuery ? "flex-start" : "center",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                width: 236,
                textAlign: tabletMediaQuery ? "left" : "right",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="E-mail Address:"
            placeholder="Email"
            key={form.key("email")}
            {...form.getInputProps("email")}
          />
          <Button
            type="button"
            variant="transparent"
            color="#000"
            styles={() => ({
              root: {
                fontSize: "1.2em",
                textDecoration: "underline",
                paddingLeft: "0",
                width: 90,
                cursor: "default",
                textAlign: "left",
              },
            })}
          ></Button>
        </Flex>

        <Flex
          gap={20}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
          style={() => ({
            paddingLeft: tabletMediaQuery ? "0" : "2em",
            display: tabletMediaQuery ? "inline-block" : "",
          })}
        >
          <Box
            style={() => ({
              position: "relative",
            })}
          >
            <TextInput
              styles={(theme) => ({
                root: {
                  display: mobileMediaQuery ? "block" : "flex",
                  flexWrap: "wrap",
                  justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  alignItems: "center",
                },
                label: {
                  paddingRight: 20,
                  fontFamily: theme.headings.fontFamily.bold,
                  fontSize: "1.2em",
                  width: 236,
                  textAlign: tabletMediaQuery ? "left" : "right",
                },
                input: {
                  paddingRight: 20,
                  border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                  maxWidth: "100%",
                  width: tabletMediaQuery ? "100%" : "22rem",
                },
              })}
              label="Current User Address:"
              placeholder="Current User Address:"
              key={form.key("last_address")}
              {...form.getInputProps("last_address")}
            />

            <CopyButton value={form.getValues().last_address} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={copy}
                    style={() => ({
                      position: "absolute",
                      top: mobileMediaQuery ? "2.15em" : "0.3em",
                      right: "-2.7em",
                    })}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Box>
          <Button
            type="button"
            variant="transparent"
            color="#000"
            styles={() => ({
              root: {
                fontSize: "1.2em",
                textDecoration: "underline",
                paddingLeft: "0",
                marginLeft: tabletMediaQuery ? "0" : "1.7em",
                width: 90,
                whiteSpace: "nowrap",
                cursor: "pointer",
                overflow: "unset",
                textAlign: "left",
              },
              label: {
                overflow: "unset",
              },
              inner: {
                overflow: "unset",
                display: "block",
              },
            })}
            onClick={() =>
              handleButtonClickUserAddressHistory(setUserAddressHistoryData)
            }
          >
            User Address History
          </Button>
        </Flex>

        <Flex
          gap={20}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
        >
          <TextInput
            styles={(theme) => ({
              root: {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: tabletMediaQuery ? "flex-start" : "center",
                alignItems: "center",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                width: 236,
                textAlign: tabletMediaQuery ? "left" : "right",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="Identity Verification Status:"
            placeholder="Identity Verification Status:"
            key={form.key("last_is_verified")}
            {...form.getInputProps("last_is_verified")}
          />
          <Button
            type="button"
            variant="transparent"
            color="#000"
            styles={() => ({
              root: {
                fontSize: "1.2em",
                textDecoration: "underline",
                paddingLeft: "0",
                width: 90,
                cursor: "default",
                textAlign: "left",
              },
            })}
          ></Button>
        </Flex>

        <Flex
          gap={20}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
        >
          <TextInput
            styles={(theme) => ({
              root: {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: tabletMediaQuery ? "flex-start" : "center",
                alignItems: "center",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                width: 236,
                textAlign: tabletMediaQuery ? "left" : "right",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="Account Created on Date:"
            placeholder="Account Created on Date:"
            key={form.key("date_joined")}
            {...form.getInputProps("date_joined")}
          />
          <Button
            type="button"
            variant="transparent"
            color="#000"
            styles={() => ({
              root: {
                fontSize: "1.2em",
                textDecoration: "underline",
                paddingLeft: "0",
                width: 90,
                cursor: "default",
                textAlign: "left",
              },
            })}
          ></Button>
        </Flex>
      </form>

      {!hideInProgress && (
        <>
          <Flex gap={20} mb={32} align="center" justify="center">
            <Button
              type="button"
              variant="transparent"
              color="#000"
              styles={(theme) => ({
                root: {
                  fontSize: "1.2em",
                  textDecoration: "underline",
                  paddingLeft: "0",
                  cursor: "pointer",
                  overflow: "unset",
                  marginTop: 20,
                  fontFamily: theme.headings.fontFamily.light,
                },
              })}
              onClick={() => handleButtonClickChangePassword()}
            >
              Change Password
            </Button>
          </Flex>

          <Flex gap={20} mb={32} align="center" justify="center">
            <Button
              type="button"
              variant="transparent"
              color="#000"
              styles={(theme) => ({
                root: {
                  fontSize: "1.2em",
                  textDecoration: "underline",
                  paddingLeft: "0",
                  cursor: "pointer",
                  overflow: "unset",
                  marginTop: 20,
                  fontFamily: theme.headings.fontFamily.light,
                  color: theme.customColors.red,
                },
              })}
              onClick={() => handleButtonClickUserDelete()}
            >
              Cancel Account
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

UserInfo.propTypes = {
  setUserAddressHistoryData: PropTypes.func.isRequired,
  setUserDelete: PropTypes.func,
  setChangePassword: PropTypes.func,
  setChangedUserName: PropTypes.func,
  changedUserName: PropTypes.bool,
};

export default UserInfo;
