import { sha3_256_hash_string } from "../cryptoUtils";
import { notifications } from "@mantine/notifications";
import { parseMetadata } from "./parseMetadata";
import { stringToTimestamp } from "./stringToTimestamp";

/**
 * Convert CSV data to JSON format
 */
const csvToJson = (csvData) => {
  const lines = csvData.split("\n");
  const headers = lines[0].split(",");
  const json = lines.slice(1).reduce((acc, line) => {
    if (line.trim() === "") return acc; // if empty lines skip
    const values = line.split(",");
    const obj = headers.reduce((headerAcc, header, idx) => {
      headerAcc[header.trim()] = values[idx]?.trim(); // Map object keys to values
      return headerAcc;
    }, {});
    acc.push(obj);
    return acc;
  }, []);
  return json;
};

/**
 * Convert JSON data to CSV format
 * @param {*} jsonData
 * @param {*} headers
 * @returns
 */
const jsonToCsv = (jsonData, headers) => {
  let csv = headers.join(",") + "\n";
  jsonData.forEach((row) => {
    csv += headers.map((header) => row[header]).join(",") + "\n";
  });
  return csv;
};

/**
 * Group data by a specific column
 * @param {*} data
 * @param {*} column
 * @returns
 */
const groupBy = (data, column) => {
  const uniqueKeys = Array.from(new Set(data.map((row) => row[column])));
  return uniqueKeys.map((key) => ({
    [column]: key,
    group: data.filter((row) => row[column] === key),
  }));
};

/**
 * Remove a specific column from data
 * @param {*} data
 * @param {*} column
 * @returns
 */
/* eslint-disable no-unused-vars */
const removeColumn = (data, column) => {
  return data.map((row) => {
    const { [column]: _, ...rest } = row; // Exclude the specified column
    return rest;
  });
};

const tryParseStringToTimestamp = (extractedDatetime) => {
  try {
    const date = stringToTimestamp(extractedDatetime);
    return date.toUTC().toFormat("yyyy-MM-dd HH:mm:ss+00:00");
  } catch (error) {
    console.log(error);
    notifications.show({
      title: "Error",
      message: error.message,
      color: "red",
    });
    return null;
  }
};

/**
 * Process CSV data
 * @param {*} dataLines
 * @returns
 */
const processCsvData = (dataLines, collectionTimezone) => {
  const jsonData = csvToJson(dataLines);

  return jsonData.map((row) => {
    row.t = tryParseStringToTimestamp(row.t);
    return row;
  });
};

const processCSVWithHash = async (csvData, onErrorMessage) => {
  let defaultTimezone = "";
  const lines = csvData.split("\n");
  // Check if metadata exists
  if (lines.length < 2) {
    notifications.show({
      title: "Error",
      message: "Metadata is missing in the CSV file.",
      color: "red",
    });
    onErrorMessage("Metadata is missing in the CSV file.");
    return null;
  }

  const {
    collectionName = "",
    userAddress = "",
    collectionTimezone,
  } = parseMetadata(lines, defaultTimezone);
  // Validation for collectionName
  const unsafeCharacters = /[<>#%{}|\\^~[\]`]/g;
  if (!collectionName) {
    notifications.show({
      title: "Error",
      message: "Collection name is missing.",
      color: "red",
    });
    onErrorMessage("Collection name is missing.");
    return null;
  }

  if (collectionName.length < 3 || collectionName.length > 250) {
    notifications.show({
      title: "Error",
      message: "Collection name must be between 3 and 250 characters long.",
      color: "red",
    });
    onErrorMessage(
      "Collection name must be between 3 and 250 characters long.",
    );
    return null;
  }

  if (collectionName.match(unsafeCharacters)) {
    notifications.show({
      title: "Error",
      message: "Collection name contains unsafe characters.",
      color: "red",
    });
    onErrorMessage("Collection name contains unsafe characters.");
    return null;
  }

  // Validation for userAddress
  const addressRegex = /^0x[a-fA-F0-9]{40}$/;
  if (!userAddress) {
    notifications.show({
      title: "Error",
      message: "User address is missing.",
      color: "red",
    });
    onErrorMessage("User address is missing.");
    return null;
  }

  if (!addressRegex.test(userAddress)) {
    notifications.show({
      title: "Error",
      message:
        "User address must be a valid Ethereum address (0x followed by 40 hex characters).",
      color: "red",
    });

    onErrorMessage(
      "User address must be a valid Ethereum address (0x followed by 40 hex characters).",
    );
    return null;
  }

  const dataLines = lines.slice(2).join("\n");
  const jsonData = processCsvData(dataLines, collectionTimezone);
  const groupedData = groupBy(jsonData, "t");

  const rows = groupedData.map((group) => {
    const cleanedData = removeColumn(group.group, "t");
    const headers = Object.keys(cleanedData[0]);
    const csvContent = jsonToCsv(cleanedData, headers);
    const hash = sha3_256_hash_string(csvContent);
    return `${group.t},${hash},${hash}`;
  });
  const finalCsv = `collection_name,user_address,collection_timezone\n${collectionName},${userAddress},${collectionTimezone}\nt,c,f\n${rows.join(
    "\n",
  )}`;
  return finalCsv;
};

export const processCsv = async (csvData, onErrorMessage) => {
  const finalCsv = processCSVWithHash(csvData, onErrorMessage);
  return finalCsv;
};
